import React, { FC, useState, useCallback } from "react"
import { Tag, Input, Tooltip, Spin, message } from "antd"
import _ from "lodash"

import { useCart } from "../../context/CartContext"
import { Promotion } from "../../models/promotion"
import useFetchPromotions from "./hooks/useFetchPromotions"

export default () => {
  const { loading, promotions, error, reload } = useFetchPromotions("active")

  return loading ? (
    <Spin
      size="large"
      className="mx-auto"
      tip="LOADING..."
      style={{ display: "block", marginLeft: "auto" }}
    />
  ) : (
    <>
      {error ? (
        message.error(error)
      ) : (
        <>
          {promotions.length > 0 && <PromotionInput promotions={promotions} />}
        </>
      )}
    </>
  )
}

const PromotionInput: FC<{ promotions: Promotion[] }> = ({ promotions }) => {
  const cart = useCart()
  const [promoCode, setPromoCode] = useState<string>("")

  const handleRemove = useCallback((id: string) => {
    cart.removePromo(id)
  }, [])

  const handleInputChange = useCallback(e => {
    setPromoCode(e.target.value)
  }, [])

  const handleInputConfirm = useCallback(
    e => {
      e.preventDefault()

      if (promoCode !== "") {
        // check to see if the promoCode exists in any active promotions
        const foundedPromo = _.find(promotions, { code: promoCode })
        if (foundedPromo !== undefined) {
          // check to see if the code has already been added
          if (_.findIndex(cart.promotions, { code: promoCode }) === -1) {
            cart.addPromo(foundedPromo)
            setPromoCode("")
          }
        } else {
          message.warning(
            "Invalid promo code. Please keep in mind that promo code is case sensitive!!!"
          )
        }
      }
    },
    [promoCode]
  )

  return (
    <>
      <Input
        placeholder="Enter Promotion Code (optional)"
        value={promoCode}
        onChange={handleInputChange}
        onBlur={handleInputConfirm}
        onPressEnter={handleInputConfirm}
      />

      {cart.promotions.map(promo => {
        const isLongTag = promo.code.length > 20

        const tagElem = (
          <Tag
            className="mt-2"
            key={promo.id}
            color="blue"
            closable
            onClose={() => handleRemove(promo.id)}
          >
            <span>
              {isLongTag ? `${promo.code.slice(0, 20)}...` : promo.code}
            </span>
          </Tag>
        )

        return isLongTag ? (
          <Tooltip title={promo.code} key={promo.id}>
            {tagElem}
          </Tooltip>
        ) : (
          tagElem
        )
      })}
    </>
  )
}
