export interface StoreHour {
  [key: number]: {
    open: string
    close: string
  }
}

export const STORE_HOURS: StoreHour = {
  1: {
    open: "09:30",
    close: "19:00",
  },
  2: {
    open: "09:30",
    close: "19:00",
  },
  3: {
    open: "09:30",
    close: "19:00",
  },
  4: {
    open: "09:30",
    close: "19:00",
  },
  5: {
    open: "09:30",
    close: "19:00",
  },
  6: {
    open: "09:30",
    close: "19:00",
  },
  0: {
    open: "10:00",
    close: "18:00",
  },
}
