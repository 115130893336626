import { useState, useCallback, useEffect } from "react"
import { Promotion } from "../../../models/promotion"
import { fetchPromotions } from "../../../services/ic.service"

type FetchPromotionsState = {
  loading: boolean
  promotions: Promotion[]
  error: Error
  reload?: () => void
}

export default (
  status: "active" | "expired" | "featured"
): FetchPromotionsState => {
  const [loading, setLoading] = useState<boolean>(true)
  const [promotions, setPromotions] = useState<Promotion[]>()
  const [error, setError] = useState<Error>()

  const load = useCallback(() => {
    setLoading(true)
    fetchPromotions(status)
      .then(res => setPromotions(res.data))
      .catch(error => setError(error))
      .finally(() => setLoading(false))
  }, [])

  useEffect(() => {
    load()
  }, [load])

  return { loading, promotions, error, reload: load }
}
