import React, { FC, useCallback, useState, useMemo } from "react"
import { Link } from "gatsby"
import { Formik, useFormikContext } from "formik"
import {
  Avatar,
  message,
  Row,
  Col,
  Typography,
  Result,
  Button,
  Divider,
  Tag,
} from "antd"
import { Form, Select, DatePicker, Input, SubmitButton } from "formik-antd"
import * as Yup from "yup"
import _ from "lodash"
import dayjs from "dayjs"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import LineItemList from "../components/line-item/list"
import PromotionInput from "../components/promotion/input"
import TextInput from "../components/data-entry/TextInput"

import { STORE_HOURS } from "../models/store-hour"

import useFetchTechnicians from "../components/technician/hooks/useFetchTechnicians"
import useFetchDrinks from "../hooks/useFetchDrinks"

import { useCart } from "../context/CartContext"

import { createNewAppointment } from "../services/ic.service"
// import Appointment from "../models/appointment"

import {
  calculateCartTotal,
  calculateCartTotalEstTime,
  calNextAvailableTime,
  minToHourFormat,
  timeToDate,
  getTimePickerInHour,
  isToday,
} from "../ultilities"

interface ExtendCheckableTagProps {
  disabled: boolean
}

const StyledCheckableTag = styled(Tag.CheckableTag)<ExtendCheckableTagProps>`
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  cursor: ${props => props.disabled && "not-allowed"};
  color: ${props => props.disabled && "rgba(0, 0, 0, 0.25)"};
  background: ${props => props.disabled && "#f5f5f5"};
  border-color: ${props => props.disabled && "#d9d9d9"};
  text-shadow: ${props => props.disabled && "none"};
  box-shadow: ${props => props.disabled && "none"};
`

const appointmentSchema = Yup.object().shape({
  name: Yup.string().required("Please fill out your name"),
  technician: Yup.string().nullable(),
  date: Yup.string()
    .required("Please specify the date")
    .typeError("Please select a date"),
  drink: Yup.string(),
  phone_number: Yup.string()
    .required("phone number is required")
    .length(10, "Invalid input"),
  note: Yup.string(),
})

const initialValues = {
  name: "",
  technician: "da56df2e-209e-4d57-94d7-6e4055efa96b",
  date: new Date().toISOString(),
  drink: "29640ab4-c795-43cc-a564-da1d9ec88116",
  note: "",
  phone_number: "",
}

const { Option } = Select

function getTimeSlots(selectedDate: string): string[] {
  let data: string[] = []
  const day: number = dayjs(selectedDate).day()
  // TODO: fetch from db. Right now hard coding minimum 15mins
  const minimumServiceTime: number = 15

  const { open, close } = STORE_HOURS[day]
  const start = getTimePickerInHour(open)
  const end = getTimePickerInHour(close)

  data.push(open)

  let prev: number = start

  for (let i = 0; i < ((end - start) * 60) / minimumServiceTime; i++) {
    const cur = calNextAvailableTime(prev, minimumServiceTime)
    data.push(cur)
    prev = getTimePickerInHour(cur)
  }

  return data
}

const Checkout: FC<{}> = ({}) => {
  // const { values } = useFormikContext<Appointment>()

  const cart = useCart()
  const [isSuccessful, setIsSuccessful] = useState<boolean>(false)
  const [selectedSlot, setSelectedSlot] = useState<string>("")

  const handleOnSubmit = useCallback(
    (values, actions) => {
      const { technician, date, drink, phone_number, note, name } = values

      const start_time = selectedSlot
      const end_time = timeToDate(selectedSlot)
        .add(calculateCartTotalEstTime(cart.lineItems), "minutes")
        .format("HH:mm")

      console.log(dayjs(date).format("YYYY-MM-DD"))

      const payload = {
        name: _.escapeRegExp(name),
        technician,
        date: new Date(date),
        start_time,
        drink,
        phone_number: _.escapeRegExp(phone_number),
        note: _.escapeRegExp(note),
        line_items: cart.lineItems,
        status: "unconfirmed",
        end_time,
        est_total: calculateCartTotal(cart.lineItems, cart.promotions).total,
        promotions: cart.promotions,
      }

      createNewAppointment(payload)
        .then(() => {
          actions.resetForm()
          localStorage.clear()
          setIsSuccessful(true)
          cart.resetCart()
        })
        .catch(error => {
          message.error(error)
        })
        .finally(() => actions.setSubmitting(false))
    },
    [selectedSlot, cart]
  )

  const disabledDate = current => {
    return dayjs(current).isBefore(dayjs().subtract(1, "day"))
  }

  const handleSelectTimeSlot = useCallback(
    (e: React.MouseEvent<HTMLSpanElement>) => {
      const { slot } = e.currentTarget.dataset
      if (
        isToday(dayjs().add(1, "day").format("MM-DD-YYYY")) &&
        getTimePickerInHour(slot) < getTimePickerInHour(dayjs().format("HH:mm"))
      ) {
        message.error("You can not pick time slot in the past")
      } else {
        setSelectedSlot(selectedSlot => {
          if (selectedSlot === "") {
            return slot
          } else if (selectedSlot === slot) {
            // this is toggle case
            return ""
          }

          return slot
        })
      }
    },
    []
  )

  return (
    <Layout>
      <SEO title="Checkout" description="Checkout your appointment" />

      <Typography.Title level={1} className="px-2 py-2 bg-light">
        CHECK OUT
      </Typography.Title>

      {cart.lineItems?.length > 0 ? (
        <>
          <div className="px-2 py-2">
            <LineItemList lineItems={cart.lineItems} />
          </div>

          <Divider />

          <Formik
            initialValues={initialValues}
            isInitialValid={false}
            onSubmit={handleOnSubmit}
            validationSchema={appointmentSchema}
          >
            {({ values, handleSubmit, isValid }) => {
              return (
                <Form className="px-2">
                  <Row gutter={[12, 0]}>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      <TextInput
                        name="name"
                        label="Name"
                        type="text"
                        required
                      />
                    </Col>
                    <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                      <TextInput
                        name="phone_number"
                        label="Phone Number"
                        type="phoneNumber"
                        required
                      />
                    </Col>
                  </Row>

                  <Row gutter={4}>
                    <Col span={24}>
                      <Form.Item name="date" label="Date" required>
                        <DatePicker name="date" disabledDate={disabledDate} />
                      </Form.Item>
                    </Col>
                  </Row>

                  {values.date && (
                    <Row>
                      <Col span="24">
                        {getTimeSlots(values.date).map(slot => {
                          return (
                            <StyledCheckableTag
                              data-slot={`${slot}`}
                              checked={selectedSlot === slot}
                              onClick={handleSelectTimeSlot}
                              style={{ margin: "0.5rem" }}
                              key={slot}
                              disabled={
                                isToday(values.date) &&
                                getTimePickerInHour(slot) <
                                  getTimePickerInHour(dayjs().format("HH:mm"))
                              }
                            >
                              {slot}
                            </StyledCheckableTag>
                          )
                        })}
                      </Col>
                    </Row>
                  )}

                  <TechnicianSelect
                    name="technician"
                    label="Do you have any prefer technician?"
                  />

                  <DrinkSelect
                    name="drink"
                    label="Pick your complimentary drinks"
                  />

                  <Form.Item name="note" label="Note">
                    <Input.TextArea name="note" />
                  </Form.Item>

                  <Row gutter={[0, 12]}>
                    <Col span={24}>
                      <PromotionInput />
                    </Col>
                  </Row>

                  <Row className="bg-light p-2 rounded shadow-sm">
                    <Col
                      span={
                        cart.promotions && cart.promotions.length > 0 ? 8 : 16
                      }
                    >
                      <Typography.Text type="secondary">
                        Est Time
                      </Typography.Text>
                      <Typography.Title level={5}>
                        {minToHourFormat(
                          calculateCartTotalEstTime(cart.lineItems)
                        )}
                      </Typography.Title>
                    </Col>

                    {cart.promotions && cart.promotions.length > 0 && (
                      <Col span={8}>
                        <Typography.Text type="secondary">
                          Discount
                        </Typography.Text>
                        <Typography.Title level={5}>
                          ${" "}
                          {
                            calculateCartTotal(cart.lineItems, cart.promotions)
                              .totalDiscount
                          }
                        </Typography.Title>
                      </Col>
                    )}

                    <Col span={8}>
                      <Typography.Text type="secondary">
                        Est Total
                      </Typography.Text>
                      <Typography.Title level={5}>
                        ${" "}
                        {
                          calculateCartTotal(cart.lineItems, cart.promotions)
                            .total
                        }
                      </Typography.Title>
                    </Col>
                  </Row>

                  <br />

                  <SubmitButton disabled={!isValid || selectedSlot === ""}>
                    LOOKS GOOD, BOOK
                  </SubmitButton>
                </Form>
              )
            }}
          </Formik>
        </>
      ) : (
        <div className="full-view-height">
          {isSuccessful ? (
            <Result
              status="success"
              title="Yayy, You are AWESOMEE!!!"
              subTitle="Thank you so much for booking with us!!! Our receiptionist will confirm and contact you shortly."
              extra={[
                <Link to="/booking">
                  <Button type="primary" key="book more">
                    Book More
                  </Button>
                </Link>,
                <Link to="/">
                  <Button key="buy">Home</Button>
                </Link>,
              ]}
            />
          ) : (
            <Typography.Title level={5} className="px-2">
              Your cart is empty. Please add some services then come back
            </Typography.Title>
          )}
        </div>
      )}
    </Layout>
  )
}

export default Checkout

const TechnicianSelect: FC<{ name: string; label: string }> = ({
  name,
  label,
}) => {
  const { loading, technicians, error } = useFetchTechnicians()

  return loading ? (
    <Select name={name} loading />
  ) : (
    <>
      {error ? (
        message.error("Error loading technicians")
      ) : (
        <Form.Item name={name} label={label}>
          <Select name={name}>
            {technicians.map(technician => {
              return (
                <Option
                  key={technician.id}
                  value={technician.id}
                  label={technician.name}
                >
                  <div>
                    {technician.name !== "anyone available" && (
                      <span role="img" aria-label={technician.name}>
                        <Avatar src={technician.profile_image} size="small" />
                      </span>
                    )}

                    {_.startCase(technician.name)}
                  </div>{" "}
                </Option>
              )
            })}
          </Select>
        </Form.Item>
      )}
    </>
  )
}

const DrinkSelect: FC<{ name: string; label: string }> = ({ name, label }) => {
  const { loading, drinks, error } = useFetchDrinks()

  return loading ? (
    <Select name={name} loading />
  ) : (
    <>
      {error ? (
        message.error("Error loading drinks")
      ) : (
        <Form.Item name={name} label={label}>
          <Select name={name} allowClear>
            {drinks.map(drink => {
              return (
                <Option key={drink.id} value={drink.id} label={drink.name}>
                  <div>
                    {drink.pict && (
                      <span role="img" aria-label={drink.name}>
                        <Avatar src={drink.pict} size="small" />
                      </span>
                    )}

                    {_.startCase(drink.name)}
                  </div>
                </Option>
              )
            })}
          </Select>
        </Form.Item>
      )}
    </>
  )
}
