import { useState, useCallback, useEffect } from "react";
import { Technician } from "../../../models/technician";
import { fetchTechnicians } from "../../../services/ic.service";

type FetchTechniciansState = {
  loading: boolean;
  technicians: Technician[];
  error: Error;
  reload?: () => void;
};

export default (): FetchTechniciansState => {
  const [loading, setLoading] = useState<boolean>(true);
  const [technicians, setTechnicians] = useState<Technician[]>();
  const [error, setError] = useState<Error>();

  const load = useCallback(() => {
    setLoading(true);
    fetchTechnicians()
      .then((res) => setTechnicians(res.data))
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    load();
  }, [load]);

  return { loading, technicians, error, reload: load };
};
