import React, { useCallback, Fragment } from "react"
import _ from "lodash"
import {
  Typography,
  Row,
  Col,
  Tag,
  Space,
  InputNumber,
  Button,
  Divider,
} from "antd"

import { useCart, LineItem } from "../../context/CartContext"
import { minToHourFormat } from "../../ultilities"

const { Title, Text, Paragraph } = Typography

function LineItemList(props: { lineItems: LineItem[] }): JSX.Element {
  const cart = useCart()

  const handleRemoveItem = useCallback(e => {
    cart.removeItem(e.currentTarget.dataset.id)
  }, [])

  return (
    <>
      {props.lineItems.map((lineItem, index) => {
        return (
          <Fragment key={lineItem.item.id}>
            <Title level={4}>
              {index + 1}. {lineItem.item.name}
            </Title>

            <Row>
              <Col span={24}>
                <Tag color="#bd9d95">{_.startCase(lineItem.item.category)}</Tag>
                {lineItem.item.recommend_level && (
                  <>
                    {lineItem.item.recommend_level === "recommended" && (
                      <Tag color="#5f001d">
                        {_.startCase(lineItem.item.recommend_level)}
                      </Tag>
                    )}
                  </>
                )}
              </Col>
            </Row>

            <Space size="large">
              <Text>
                Est Time:{" "}
                <strong className="text-secondary">
                  {minToHourFormat(lineItem.item.estimate_service_time)}
                </strong>
              </Text>

              <Text>
                Price:{" "}
                <strong className="text-primary">${lineItem.item.price}</strong>
              </Text>
            </Space>

            <Paragraph ellipsis={{ rows: 2, expandable: true, symbol: "more" }}>
              {lineItem.item.description}
            </Paragraph>

            <Row align="middle">
              <Col span={16}>
                <Space>
                  <Text>QTY</Text>
                  <InputNumber
                    size="small"
                    min={1}
                    max={20}
                    defaultValue={1}
                    value={lineItem.quantity}
                    onChange={value => {
                      if (value && value > 0) {
                        cart.updateItemQty(lineItem.item.id, Number(value))
                      }
                    }}
                  />
                  <Button
                    data-id={lineItem.item.id}
                    type="default"
                    shape="round"
                    onClick={handleRemoveItem}
                    style={{ color: "red" }}
                  >
                    Remove
                  </Button>
                </Space>
              </Col>
            </Row>
            {props.lineItems.length !== index + 1 && <Divider />}
          </Fragment>
        )
      })}
    </>
  )
}

export default LineItemList
